// local imports
import { FaMailchimp, FaWhatsapp } from "react-icons/fa";
import { SiGmail } from "react-icons/si";
import {
  footerLinksColumnOne,
  footerLinksColumnTwo,
  newsletter,
} from "../data";

import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer class="relative bg-white pt-8  pb-6">
    <hr class="my-6 border-black"/>
    <div class="container mx-auto px-4">
      <div class="flex flex-wrap text-left lg:text-left ">
        <div class="w-full lg:w-6/12 px-4 flex flex-col gap-3">
          <h4 class="text-3xl fonat-semibold text-black">Let's keep in Touch!</h4>
          <h5 class="text-sm mt-0 mb-2 text-black">
            Connect with us on these platforms.
          </h5>
          <div class="social-icons social-icons">
            <ul className="flex flex-row gap-3">
              <a href="https://api.whatsapp.com/send?phone=%2B919207217770">

             <FaWhatsapp className=" w-6 h-6 transition-transform duration-300 transform hover:scale-110"/>
              </a>
              <a href="mailto:rayamattress@gmail.com">

             <SiGmail className="w-6 h-6 transition-transform duration-300 transform hover:scale-110"/>
              </a>
            </ul>
          </div>
        </div>
        <div class="w-full lg:w-6/12 px-4 mt-6 lg:mt-3">
          <div class="flex flex-wrap items-top mb-6">
            <div class="w-full lg:w-6/12 lg:px-4 ml-auto">
              <span class="block uppercase text-black text-sm font-semibold mb-2">Useful Links</span>
              <ul class="list-unstyled">
              {window.location.pathname === "/" ? (
              <a href="#product">
                <li className="text-lg">Mattress</li>
              </a>
            ) : (
              /* If not on the homepage, use regular link */
              <Link to="/#product">
                <li className="text-lg">Mattress</li>
              </Link>
            )}
               {window.location.pathname === "/" ? (
              <a href="https://api.whatsapp.com/send?phone=%2B919207217770">
                <li className="text-lg">Contact Us</li>
              </a>
            ) : (
              /* If not on the homepage, use regular link */
              <Link to="https://api.whatsapp.com/send?phone=%2B919207217770">
                <li className="text-lg">Contact Us</li>
              </Link>
            )}
               
              </ul>
            </div>
            
          </div>
        </div>
      </div>
      <hr class="my-6 border-white "/>
      <div class="col-lg-6 text-center ml-auto mr-auto" >
        <div class="copyright-text">
         Raya mattress All Rights Reserved. | Designed by
          <a href="https://codx.tech" class="text-black underline ml-1">CODX</a>
        </div>
        
      </div>
    </div>
  </footer>
  );
};

export default Footer;
