// local imports
import shippingIcon from "./assets/free-shiping-icon.svg";
import paymentIcon from "./assets/quick-payment-icon.svg";
import supportIcon from "./assets/support-icon.svg";
import productOneIcon from "./assets/product-one.svg";
import productTwoIcon from "./assets/produc-two.svg";
import productThreeIcon from "./assets/product-three.svg";
import plantIcon from "./assets/plant-icon.svg";
import sunIcon from "./assets/sun-icon.svg";
import waterIcon from "./assets/water-icon.svg";
import temperatureIcon from "./assets/temperature-icon.svg";

export const heroTitle =
  "Experience the Perfect Sleep with Our Premium Mattresses";
export const heroSubtitle =
  "Discover the ideal mattress for your ultimate comfort and restful sleep, all with us.";

export const services = [
  {
    title: "Free Shipping",
    subtitle: "No charge for your delivery",
    icon: shippingIcon,
  },
  {
    title: "Quick Payment",
    subtitle: "100% secure",
    icon: paymentIcon,
  },
  {
    title: "24/7 Support",
    subtitle: "Don't hesitate to contact us",
    icon: supportIcon,
  },
];

export const productsTitle = "Best Selling Products";
export const productsBtnText = "See Colection";

export const products = [
  {
    id: 1,
    title: "Atlas ",
    price: "$65.99",
    img: "/atlas.jpg",

    quote:
    "Precision-engineered for unparalleled comfort and support. Crafted with premium materials and innovative design, it contours to your body for restorative sleep. Say goodbye to restless nights with motion isolation technology. Choose your perfect comfort level and elevate your sleep with Atlas. " ,
    inner:
    "Bonnel spring hard felt High  density PU foam & Memory foam  ", 
    types: [
      {
        title: "Atlas Standard",
        img: "/atlas1.jpg",
        details: [
          {
            img: "/atlas1.jpg",
            sizes: [
              "72 x 36",
              "75 x 36",
              "78 x 36",
              "72 x 48",
              "75 x 48",
              "78 x 48",
              "72 x 60",
              "75 x 60",
              "78 x 60",
              "72 x 72",
              "75 x 72",
              "78 x 72",
              " ",
              " ",
              " ",
              "Inches (IN)",
              "8",
              "10",
              "12",
              
            ],
          },
        ],
      },
      {
        title: "Atlas Pillow Top",
        img: "/atlas2.jpg",
        details: [
          {
            img: "/atlas1.jpg",
            sizes: [
              "72 x 36",
              "75 x 36",
              "78 x 36",
              "72 x 48",
              "75 x 48",
              "78 x 48",
              "72 x 60",
              "75 x 60",
              "78 x 60",
              "72 x 72",
              "75 x 72",
              "78 x 72",
              " ",
              " ",
              " ",
              "Inches (IN)",
              "8",
              "10",
              "12",
              
            ],
          },
        ],
      },
      {
        title: "Atlas Euro Top",
        img: "/atlas3.jpg",
        details: [
          {
            img: "/atlas1.jpg",
            sizes: [

              "72 x 36",
              "75 x 36",
              "78 x 36",
              "72 x 48",
              "75 x 48",
              "78 x 48",
              "72 x 60",
              "75 x 60",
              "78 x 60",
              "72 x 72",
              "75 x 72",
              "78 x 72",
              " ",
              " ",
              " ",
              "Inches (IN)",
              "8",
              "10",
              "12",
            ],
          },
        ],
      },
    ],
  },
  {
    id: 2,
    title: "Heera ",
    price: "$75.00",
    img: "/heera.jpg",
    innerImg:"/inner.jpg",
    quote:
    " Discover the Heera mattress, meticulously crafted for exceptional comfort and support. Engineered with premium materials and advanced design, it contours to your body's shape for a restorative night's sleep. Bid farewell to sleep disturbances with its motion isolation technology. Find your ideal comfort level and elevate your sleep with Heera.", 
    inner:
    "pocketed spring hard cotton felt high PU foam, super soft foam",
    types: [
      {
        title: "Heera Standard",
        img: "/heera1.jpg",
        details: [
          {
            img: "/atlas1.jpg",
            sizes: [
              "72 x 36",
              "75 x 36",
              "78 x 36",
              "72 x 48",
              "75 x 48",
              "78 x 48",
              "72 x 60",
              "75 x 60",
              "78 x 60",
              "72 x 72",
              "75 x 72",
              "78 x 72",
              " ",
              " ",
              " ",
              "Inches (IN)",
              "6",
              "8",
              "10",
              "12",
            ],
          },
        ],
      },
      {
        title: "Heera Pillow Top",
        img: "/heera2.jpg",
        details: [
          {
            img: "/atlas1.jpg",
            sizes: [
              "72 x 36",
              "75 x 36",
              "78 x 36",
              "72 x 48",
              "75 x 48",
              "78 x 48",
              "72 x 60",
              "75 x 60",
              "78 x 60",
              "72 x 72",
              "75 x 72",
              "78 x 72",
              " ",
              " ",
              " ",
              "Inches (IN)",
              "6",
              "8",
              "10",
              "12",
            ],
          },
        ],
      },
      {
        title: "Heera Euro Top",
        img: "/heerasub3.jpg",
        details: [
          {
            img: "/atlas1.jpg",
            sizes: [
              "72 x 36",
              "75 x 36",
              "78 x 36",
              "72 x 48",
              "75 x 48",
              "78 x 48",
              "72 x 60",
              "75 x 60",
              "78 x 60",
              "72 x 72",
              "75 x 72",
              "78 x 72",
              " ",
              " ",
              " ",
              "Inches (IN)",
              "6",
              "8",
              "10",
              "12",
            ],
          },
        ],
      },
    ],
  },
  {
    id: 3,
    title: "OrthoX ",
    price: "$88.50",
    img: "/heeradeluxe.jpg",
    quote:
    "Mattresses in this series are produced with utmost care and accuracy using the mixture of 'Natural Coir Fibers' 'PU Foam and Rebonded Foam ',Moreover the use of high quality 'Knitted Jacquard Fabrics' for exterior cover give you great relief from the heat and gives a cool feeling while lying down. ",  
    inner:
    "High Density Re-Bonded Foam & High Density Rubberized Coir", 
    types: [
      {
        title: "OrthoX Standard",
        img: "/ortho1.jpg",
        details: [
          {
            img: "/atlas1.jpg",
            sizes: [
              "72 x 36",
              "75 x 36",
              "78 x 36",
              "72 x 48",
              "75 x 48",
              "78 x 48",
              "72 x 60",
              "75 x 60",
              "78 x 60",
              "72 x 72",
              "75 x 72",
              "78 x 72",
              " ",
              " ",
              " ",
              "Inches (IN)",
              "4",
              "5",
              "6",
              "8",
              "10",
            ],
          },
        ],
      },
      {
        title: "OrthoX pillow top",
        img: "/ortho2.jpg",
        details: [
          {
            img: "/atlas1.jpg",
            sizes: [
              "72 x 36",
              "75 x 36",
              "78 x 36",
              "72 x 48",
              "75 x 48",
              "78 x 48",
              "72 x 60",
              "75 x 60",
              "78 x 60",
              "72 x 72",
              "75 x 72",
              "78 x 72",
              " ",
              " ",
              " ",
              "Inches (IN)",
              "4",
              "5",
              "6",
              "8",
              "10",
            ],
          },
        ],
      },
      {
        title: "OrthoX euro top",
        img: "/ortho3.jpg",
        details: [
          {
            img: "/atlas1.jpg",
            sizes: [
              "72 x 36",
              "75 x 36",
              "78 x 36",
              "72 x 48",
              "75 x 48",
              "78 x 48",
              "72 x 60",
              "75 x 60",
              "78 x 60",
              "72 x 72",
              "75 x 72",
              "78 x 72",
              " ",
              " ",
              " ",
              "Inches (IN)",
              "4",
              "5",
              "6",
              "8",
              "10",
            ],
          },
        ],
      },
    ],
  },
  {
    id: 4,
    title: "Cresta ",
    price: "$88.50",
    img: "/cresta-new.jpg",
    quote:
    "This golden range of mattresses has high density PU foam and rebonded foam in it's core. The highly elastic bonnel springs offer good flexiblity and all of them come with super fine fabric covering high quality 'Knitted Jacquard Fabric' ", 
    inner:
      " Bonnel coil spring, hard cotton felt High density PU foam, super soft foam & Rebonded foam",
    types: [
      {
        title: "Cresta Standard",
        img: "/cresta1.jpg",
        details: [
          {
            img: "/atlas1.jpg",
            sizes: [
              "72 x 36",
              "75 x 36",
              "78 x 36",
              "72 x 48",
              "75 x 48",
              "78 x 48",
              "72 x 60",
              "75 x 60",
              "78 x 60",
              "72 x 72",
              "75 x 72",
              "78 x 72",
              " ",
              " ",
              " ",
              "Inches (IN)",
              "8",
              "10",
              "12",
            ],
          },
        ],
      },
      {
        title: "Cresta Pillow Top",
        img: "/cresta2.jpg",
        details: [
          {
            img: "/atlas1.jpg",
            sizes: [
              "72 x 36",
              "75 x 36",
              "78 x 36",
              "72 x 48",
              "75 x 48",
              "78 x 48",
              "72 x 60",
              "75 x 60",
              "78 x 60",
              "72 x 72",
              "75 x 72",
              "78 x 72",
              " ",
              " ",
              " ",
              "Inches (IN)",
              "8",
              "10",
              "12",
            ],
          },
        ],
      },
      {
        title: "Cresta Euro Top",
        img: "/cresta3.jpg",
        details: [
          {
            img: "/atlas1.jpg",
            sizes: [
              "72 x 36",
              "75 x 36",
              "78 x 36",
              "72 x 48",
              "75 x 48",
              "78 x 48",
              "72 x 60",
              "75 x 60",
              "78 x 60",
              "72 x 72",
              "75 x 72",
              "78 x 72",
              " ",
              " ",
              " ",
              "Inches (IN)",
              "8",
              "10",
              "12",
            ],
          },
        ],
      },
    ],
  },
  {
    id: 5,
    title: "Mediline ",
    price: "$88.50",
    img: "/heera3.jpg",
    inner: "High density re-bonded Foam & super soft foam",
    quote:
      "This Orthopaedic mattress category includes strong high-density rebonded foam.\n It also features super soft foam for perfect body support and natural spine alignment.",
      
    types: [
      {
        title: "Mediline Standard",
        img: "/medline1.jpg",
        details: [
          {
            img: "/atlas1.jpg",
            sizes: [
              "72 x 36",
              "75 x 36",
              "78 x 36",
              "72 x 48",
              "75 x 48",
              "78 x 48",
              "72 x 60",
              "75 x 60",
              "78 x 60",
              "72 x 72",
              "75 x 72",
              "78 x 72",
              " ",
              " ",
              " ",
              "Inches (IN)",
              "4",
              "5",
              "6",
              "8",
              "10",
            ],
          },
        ],
      },
      {
        title: "Mediline Pillow Top",
        img: "/medline2.jpg",
        details: [
          {
            img: "/atlas1.jpg",
            sizes: [
              "72 x 36",
              "75 x 36",
              "78 x 36",
              "72 x 48",
              "75 x 48",
              "78 x 48",
              "72 x 60",
              "75 x 60",
              "78 x 60",
              "72 x 72",
              "75 x 72",
              "78 x 72",
              " ",
              " ",
              " ",
              "Inches (IN)",
              "4",
              "5",
              "6",
              "8",
              "10",
            ],
          },
        ],
      },
      {
        title: "Mediline Euro Top",
        img: "/mediline3.jpg",
        details: [
          {
            img: "/atlas1.jpg",
            sizes: [
              "72 x 36",
              "75 x 36",
              "78 x 36",
              "72 x 48",
              "75 x 48",
              "78 x 48",
              "72 x 60",
              "75 x 60",
              "78 x 60",
              "72 x 72",
              "75 x 72",
              "78 x 72",
              " ",
              " ",
              " ",
              "Inches (IN)",
              "4",
              "5",
              "6",
              "8",
              "10",
            ],
          },
        ],
      },
    ],
  },
  {
    id: 6,
    title: "Heera deluxe",
    price: "$88.50",
    img: "/heeradeluxesub1.jpg",
    innerImg:"/inner.jpg",
    quote:
    "Indulge in the epitome of luxury with the Heera Deluxe mattress. Meticulously crafted with premium materials and sophisticated design, it delivers unparalleled comfort and support. Its advanced construction ensures personalized contouring for a rejuvenating night's sleep. Say goodbye to disruptions with cutting-edge motion isolation technology. Elevate your sleep to new heights of opulence with the Heera Deluxe mattress.",
    inner:
  "Pocketed spring hard cotton felt High density PU foam. Super soft foam & memory foam",
    types: [
      {
        title: "Memory Foam",
        img: "/heeradeluxe1.jpg",
        details: [
          {
            img: "/atlas1.jpg",
            sizes: [
              "72 x 36",
              "75 x 36",
              "78 x 36",
              "72 x 48",
              "75 x 48",
              "78 x 48",
              "72 x 60",
              "75 x 60",
              "78 x 60",
              "72 x 72",
              "75 x 72",
              "78 x 72",
              " ",
              " ",
              " ",
              "Inches (IN)",
              "8",
              "10",
              "12",
            ],
          },
        ],
      },
      {
        title: "Heera Pillow Top",
        img: "/heeradeluxe2.jpg",
        details: [
          {
            img: "/atlas1.jpg",
            sizes: [
              "72 x 36",
              "75 x 36",
              "78 x 36",
              "72 x 48",
              "75 x 48",
              "78 x 48",
              "72 x 60",
              "75 x 60",
              "78 x 60",
              "72 x 72",
              "75 x 72",
              "78 x 72",
              " ",
              " ",
              " ",
              "Inches (IN)",
              "8",
              "10",
              "12",
            ],
          },
        ],
      },
      {
        title: "Heera Euro Top",
        img: "/heeradeluxe3.jpg",
        details: [
          {
            img: "/atlas1.jpg",
            sizes: [
              "72 x 36",
              "75 x 36",
              "78 x 36",
              "72 x 48",
              "75 x 48",
              "78 x 48",
              "72 x 60",
              "75 x 60",
              "78 x 60",
              "72 x 72",
              "75 x 72",
              "78 x 72",
              " ",
              " ",
              " ",
              "Inches (IN)",
              "8",
              "10",
              "12",
            ],
          },
        ],
      },
    ],
  },
  {
    id: 7,
    title: "Natural  latex",
    price: "$88.50",
    img: "/img-new.jpg",
    innerImg:"/latexinner.jpg",
    quote:
   "Unveil the essence of pure comfort with the Natural Latex mattress. Crafted from the finest natural materials, this mattress offers luxurious support and breathability for a rejuvenating sleep experience. Embrace the natural contouring properties of latex, providing personalized comfort tailored to your body's needs. Say farewell to sleep disruptions with its motion isolation capabilities. Experience the epitome of comfort and sustainability with the Natural Latex mattress.",
    inner:
    " High Density Natural Pin Core Latex Foam",

    types: [
      {
        title: "Natural Standard",
        img: "/natural1.jpg",
        details: [
          {
            img: "/atlas1.jpg",
            sizes: [
              "72 x 36",
              "75 x 36",
              "78 x 36",
              "72 x 48",
              "75 x 48",
              "78 x 48",
              "72 x 60",
              "75 x 60",
              "78 x 60",
              "72 x 72",
              "75 x 72",
              "78 x 72",
              " ",
              " ",
              " ",
              "Inches (IN)",
              "4",
              "5",
              "6",
              "8",
              "10",
            ],
          },
        ],
      },
      {
        title: "Natural Pillow Top",
        img: "/natural2.jpg",
        details: [
          {
            img: "/atlas1.jpg",
            sizes: [
              "72 x 36",
              "75 x 36",
              "78 x 36",
              "72 x 48",
              "75 x 48",
              "78 x 48",
              "72 x 60",
              "75 x 60",
              "78 x 60",
              "72 x 72",
              "75 x 72",
              "78 x 72",
              " ",
              " ",
              " ",
              "Inches (IN)",
              "4",
              "5",
              "6",
              "8",
              "10",
            ],
          },
        ],
      },
      {
        title: "Natural Euro Top",
        img: "/natural3.jpg",
        details: [
          {
            img: "/atlas1.jpg",
            sizes: [
              "72 x 36",
              "75 x 36",
              "78 x 36",
              "72 x 48",
              "75 x 48",
              "78 x 48",
              "72 x 60",
              "75 x 60",
              "78 x 60",
              "72 x 72",
              "75 x 72",
              "78 x 72",
              " ",
              " ",
              " ",
              "Inches (IN)",
              "4",
              "5",
              "6",
              "8",
              "10",
            ],
          },
        ],
      },
    ],
  },
];

export const newsletter = "Newsletter";

export const footerLinksColumnOne = [
  {
    title: "Support",
    links: ["About Us", "Careers"],
  },
];

export const footerLinksColumnTwo = [
  {
    title: "Our Menu",
    links: ["Best Product", "Categories"],
  },
  {
    title: "Address",
    links: [
      "Balan K Nair Road, Near Rajendra Hospital,Ashokapuram Building,Calicut 673001, Kerala ",
      "rayamattress@gmail.com",
    ],
  },
];
