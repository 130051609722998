import React from 'react';
import Navbar from '../views/navbar';
import Footer from '../views/Footer';

function Gallery() {
  const images = [
    "/Gallery/Image1.jpg",
    "/Gallery/Image2.jpg",
    "/Gallery/Image3.jpg",
    "/Gallery/Image4.jpg",
  ];

  return (
    <div className="container mx-auto px-5 py-2 lg:px-32 lg:pt-24">
      <Navbar />
      <div className="flex flex-col lg:h-[50vh]">

      <div className="text-xl mb-5 font-semibold mt-10" >
        Our Product Images
      </div>
      <div className="-m-1  flex flex-wrap md:-m-2 ">
        {images.map((image, index) => (
            <div key={index} className="flex w-full sm:w-1/2 md:w-1/3 lg:w-1/4 p-1 md:p-2">
            <img className="block mx-auto w-full h-auto object-cover object-center rounded-lg shadow-md" src={image} alt="Raaya mattress images" />
          </div>
        ))}
      </div>
        </div>
      <Footer />
    </div>
  );
}

export default Gallery;
